import Todo from "./Todo";

function ModalComponent() {
  return (
    <>
      <div className="component--card">
        <h1>Modal example</h1>
        <div className="card--wrap">
          <Todo
            text="A simple Modal in React"
            parag="Dummy component, This is not a dynamic text/function. This is just to show how 'Modal works in React.'"
          />
        </div>
      </div>
    </>
  );
}
export default ModalComponent;

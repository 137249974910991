import { useNavigate } from "react-router-dom";

import NewMeetupForm from "../components/meetups/NewMeetupForm";

function NewMeetupPage() {
  const history_array = useNavigate();

  function addMeetupHandler(meetupData) {
    /* Update this url in every 30days (since its free). No need to update since i have changed the url privacy to public. 
        Which is not good, since it is accesible by everyone. */
    fetch(
      "https://react-getting-started-e1fc3-default-rtdb.firebaseio.com/meetups.json",
      {
        method: "POST",
        body: JSON.stringify(meetupData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(() => {
      history_array("/all-meetup");
    });
  }

  return (
    <section>
      <h1>Add New Meetup</h1>
      <NewMeetupForm onAddMeetup={addMeetupHandler} />
    </section>
  );
}

export default NewMeetupPage;

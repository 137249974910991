import React from "react";
import classPost from "../wordpress/AllPost.module.css";

const PageLoader = () => {
  return (
    <div className={classPost.loader}>
      <div className={classPost.loadercircle}></div>
      <span className={classPost.loadertext}>Loadingggg...</span>
    </div>
  );
};

export default PageLoader;

import { useNavigate } from "react-router-dom";
import NewComponentsForm from "../components/allcomponents/NewComponentsForm";

function NewComponentsPage() {
  const history_array = useNavigate();

  function addComponentsHandler(componentData) {
    fetch(
      "https://react-getting-started-e1fc3-default-rtdb.firebaseio.com/home_components.json",
      {
        method: "POST",
        body: JSON.stringify(componentData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(() => {
      history_array("/");
    });
  }

  return (
    <>
      <h1>Add New Component Details</h1>
      <NewComponentsForm onAddComponent = { addComponentsHandler } />
    </>
  );
}

export default NewComponentsPage;

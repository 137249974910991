import MeetupItem from "./MeetupItem";
import classes from "./MeetupList.module.css";

import Flickity from "react-flickity-component";
import "../../../src/flickity.css";
/* import { useEffect, useRef, useState } from "react"; */

const flickityOptions = {
  initialIndex: 1,
  autoPlay: 2000,
  wrapAround: true,
  draggable: true,
};

function MeetupList(props) {
  /*  const containerRef = useRef(null);
  const [scrollDirection, setScrollDirection] = useState(
    "scroll-left-to-right"
  );

  useEffect(() => {
    const container = containerRef.current;

    const handleAnimationIteration = () => {
      setScrollDirection((prevDirection) =>
        prevDirection === "scroll-left-to-right"
          ? "scroll-right-to-left"
          : "scroll-left-to-right"
      );
    };

    container.addEventListener("animationiteration", handleAnimationIteration);

    return () => {
      container.removeEventListener(
        "animationiteration",
        handleAnimationIteration
      );
    };
  }, []); */

  return (
    <>
      <ul className={`${classes.list}`} /* ref={containerRef} */>
        {props.meetuparray.map((meetup) => (
          <MeetupItem
            key={meetup.id}
            id={meetup.id}
            image={meetup.image}
            title={meetup.title}
            address={meetup.address}
            description={meetup.description}
          />
        ))}
      </ul>

      <Flickity
        className={"carousel"} // default ''
        elementType={"div"} // default 'div'
        options={flickityOptions} // takes flickity options {}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        static // default fals
      >
        {props.meetuparray.map((meetup) => (
          <MeetupItem
            key={meetup.id}
            id={meetup.id}
            image={meetup.image}
            title={meetup.title}
            address={meetup.address}
            description={meetup.description}
          />
        ))}
      </Flickity>
    </>
  );
}

export default MeetupList;

import { useState } from "react";
import Modal from "./Modal";
import Backdrop from "./Backdrop";
import Button from "../button/Button";

function Todo(props) {
    const [ modalIsOpen, setModalIsOpen ] = useState(false);

    function openTodo(){
        setModalIsOpen(true);
        
    }

    function closeModal(){
        setModalIsOpen(false);
    }

  return (
    <div className="card">
      <h2>{props.text}</h2>
      <div className="actions">
        <p>{props.parag}</p>
        <Button classnames="btn" buttontext="Add" buttonfunctionname={openTodo} />
        <Button classnames="btn" buttontext="Delete" buttonfunctionname={openTodo} />
      </div>
      { modalIsOpen && <Modal onClickCancelModal={closeModal} /> }
      { modalIsOpen  && <Backdrop onClickCancel={closeModal} />}
    </div>
  );
}

export default Todo;

import { Link } from "react-router-dom";
import classNavi from "./NavMenuList.module.css";
import FavoritesContext from "../../store/favorites-context";
import { useContext } from "react";
import { links } from "../../store/data";

function NavMenuList() {
  const favoriteCtx = useContext(FavoritesContext);

  return (
    <div className={classNavi.nav_div}>
      {links.map((link) => {
        return (
          <li key={link.id}>
            <Link to={link.url} className={classNavi.header__nav__link}>
              {link.text}
              {link.span && (
                <span className={classNavi.badge}>
                  {favoriteCtx.totalFavorites}
                </span>
              )}
            </Link>
          </li>
        );
      })}
    </div>
  );
}

export default NavMenuList;

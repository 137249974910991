import classToggle from "./FilterView.module.css";

const FilterViews = ({ selected, toggleSelected, enabletext, disabletext }) => {
  return (
    <div className={classToggle.toggle_container} onClick={toggleSelected}>
      <div
        className={`${classToggle.dialog_button} 
        ${selected ? "enabled" : classToggle.disabled}`}
      >
        {selected ? disabletext : enabletext}
      </div>
    </div>
  );
};

export default FilterViews;

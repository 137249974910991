import Button from "../button/Button";

function Modal(props) {
  return (
    <div className="modal">
      <p>Are you sure?? (Dummy content)</p>
      <Button classnames="btn btn--alt" buttontext="Cancel" buttonfunctionname={props.onClickCancelModal} />
      <Button classnames="btn" buttontext="Confirm" buttonfunctionname={props.onClickCancelModal} />
    </div>
  );
}
export default Modal;

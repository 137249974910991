import Axios from "axios";
import React, { useEffect, useState } from "react";
import AllPost from "./AllPost";
import PageLoader from "../PageLoader/PageLoader";
import PaginationButton from "../pagination/Pagination";
import FilterViews from "../wpfilter/FilterViews";
import FilterOptions from "../wpfilter/FilterOptions";

import classPost from "./AllPost.module.css";

import { IoFunnelSharp, IoGridSharp } from "react-icons/io5";
import { FaThList } from "react-icons/fa";

function WpBase() {
  const [isLoaded, isLoadedFunction] = useState(true);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [nrofpages, setNumberofpage] = useState(1);
  const [prev, setPrev] = useState(false);
  const [nex, setNex] = useState(true);

  //For Grid/Block button toggle
  const [selectedToggle, setSelectedToggle] = useState(false);

  //For Filter button toggle
  const [selectedFilterToggle, setSelectedFilterToggle] = useState(false);

  // Call's the api again, When the page number changes.
  useEffect(() => {
    isLoadedFunction(true);
    Axios.get("https://test-backend.linugeorgealex.com/wp-json/wp/v2/posts?_embed", {
      params: { page: page },
    }).then((response) => {
      // Total pages.
      setNumberofpage(response.headers["x-wp-totalpages"]);
      // Store the posts.
      setPosts(response.data);
      isLoadedFunction(false);
    });
  }, [page, setPosts]);

  if (isLoaded) {
    return <PageLoader />;
  }

  const handlePrevPage = () => {
    setPrev(page - 1 ? true : false);
    setNex(page < nrofpages ? true : false);
    setPage(page - 1 ? page - 1 : 1);
  };

  const handleNextPage = () => {
    setPrev(page - 1 ? true : false);
    setNex(page < nrofpages ? true : false);
    setPage(page < nrofpages ? page + 1 : nrofpages);
  };

  return (
    <div className="">
      <div>
        <div className={classPost.wp_filter_options}>
          <FilterViews
            selected={selectedFilterToggle}
            toggleSelected={() => {
              setSelectedFilterToggle(!selectedFilterToggle);
            }}
            enabletext={<IoFunnelSharp />}
            disabletext={<IoFunnelSharp />}
          />
          <FilterViews
            selected={selectedToggle}
            toggleSelected={() => {
              setSelectedToggle(!selectedToggle);
            }}
            enabletext={<IoGridSharp />}
            disabletext={<FaThList />}
          />
        </div>
        <hr />
      </div>
      <FilterOptions filter_show={selectedFilterToggle} />
      <h1>Posts from Linu website Test Env</h1>

      <div
        className={`${
          selectedToggle ? classPost.wp_gridd : classPost.wp_blockk
        }`}
      >
        {posts &&
          posts.map((post) => (
            <React.Fragment key={post.id}>
              <AllPost post={post} post_style={selectedToggle} />
            </React.Fragment>
          ))}
      </div>

      <PaginationButton
        previous={handlePrevPage}
        next={handleNextPage}
        current_page={page}
        total_pages={nrofpages}
        if_prev={prev}
        if_next={nex}
      />
    </div>
  );
}

export default WpBase;

/* Use personal Test blog link - for development purpose */
/* Linu - Test Local -> http://localhost:8080 */
/* Linu -> https://linugeorge.000webhostapp.com/ */
/* Linu React Main Test - https://linugeorgealex.000webhostapp.com/ */

/* SFHM -> http://sfhm.test.consids5.se */

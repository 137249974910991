import { useState, useEffect } from "react";
import AllComponents from "../components/allcomponents/AllComponents";
import PageLoader from "../components/PageLoader/PageLoader";
import axios from "axios";

function HomePage() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedcomponents, setLoadedcomponents] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    fetch(
      "https://react-getting-started-e1fc3-default-rtdb.firebaseio.com/home_components.json"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const components = [];

        for (const key in data) {
          //console.log(key);
          //console.log({ ...data[key] });
          const component = {
            id: key,
            ...data[key],
          };
          components.push(component);
        }
        setIsLoading(false);
        setLoadedcomponents(components);
      });
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  /* axios
    .get("http://localhost:8080/wp-json/wlinu/v1/settings")
    .then((response) => {
      console.log(response.data);
    }); */

  return (
    <>
      <h1>Total ({loadedcomponents.length}) Components</h1>
      <AllComponents componentsarray={loadedcomponents} />
    </>
  );
}

export default HomePage;

import { useRef } from "react";

import Card from "../ui/Card";
import classes from "../meetups/NewMeetupForm.module.css"

function NewComponentsForm(props) {
  const titleInputRef = useRef();
  const descriptionInputRef = useRef();
  const urlInputRef = useRef();
  const comptypeInputRef = useRef();

  function submitHandler(event) {
    event.preventDefault();

    const enteredTitle = titleInputRef.current.value;
    const enteredDescription = descriptionInputRef.current.value;
    const enteredurl = urlInputRef.current.value;
    const enteredcomptype = comptypeInputRef.current.value;

    const componentData = {
        title: enteredTitle,
        description: enteredDescription,
        url: enteredurl,
        type: enteredcomptype,
    };

    props.onAddComponent(componentData);
  }

  return (
    <>
      <Card>
        <form className={classes.form} onSubmit={submitHandler}>
          <div className={classes.control}>
            <label htmlFor="title">Component Name:</label>
            <input type="text" id="title" required ref={titleInputRef} />
          </div>
          <div className={classes.control}>
            <label htmlFor="description">Component Description:</label>
            <textarea
              type="text"
              id="description"
              rows="5"
              required
              ref={descriptionInputRef}
            />
          </div>
          <div className={classes.control}>
            <label htmlFor="componenturl">Component Link:</label>
            <input type="text" id="componenturl" required ref={urlInputRef} />
          </div>
          <div>
          <label htmlFor="componenttype">Component Type:</label>
          <select ref={comptypeInputRef} required>
            <option id="componenttype" value='modal' disabled>Modal (Already Added)</option>
            <option id="componenttype" value='carousel' disabled>Carousel (Already Added)</option>
            <option id="componenttype" value='worpress_rest_api' disabled>WordPress Rest API (Already Added)</option>
          </select>
          </div>
          <div className={classes.actions}>
            <button>Add Component</button>
          </div>
          <div>
            <i><b>Note:</b> copy/paste '/all-components' since components will be loaded on same page.</i>
          </div>
        </form>
      </Card>
    </>
  );
}

export default NewComponentsForm;

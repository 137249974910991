import { Link } from "react-router-dom";
import classNavi from "./MainNavigation.module.css";
import NavMenuList from "./NavMenuList";
import { useState } from "react";

import { IoMenu, IoClose } from "react-icons/io5";

function MainNavigation() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  function mobileMenu() {
    setShowMobileMenu(!showMobileMenu);
  }
  return (
    <header className={classNavi.header}>
      <div className={classNavi.header__wrap}>
        <div className={classNavi.header__logo}>
          <Link to="/">
            <img
              src="https://media.formula1.com/common/logos/f1_logo_red.svg"
              alt="React Test Logo"
            />
          </Link>
        </div>
        <button onClick={mobileMenu} className={classNavi.header__menu}>
          <IoMenu />
        </button>
        {showMobileMenu && (
          <nav className={classNavi.nav}>
            <button onClick={mobileMenu} className={classNavi.header__close}>
              <IoClose />
            </button>
            <NavMenuList />
          </nav>
        )}
      </div>
    </header>
  );
}

export default MainNavigation;

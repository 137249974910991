import { createContext, useState } from "react";

const FavoritesContext = createContext({
  favorites: [],
  totalFavorites: 0,
  addFavorite: (favoriteMeetup) => {},
  removeFavorite: (meetupId) => {},
  itemIsFavorite: (meetupId) => {},
});

export function FavoritesContextProvider(props) {
  const [useFavorites, setUseFavorites] = useState([]);

   /* ------- Saving to local storage in browser -------
      import 'useEffect' if we want to use this commented section.
   
   useEffect(() => {
    localStorage.setItem('localItemsFavourite', JSON.stringify(useFavorites));
  }, [useFavorites]);

  useEffect(() => {
    const localItemsFavouriteHolder = JSON.parse(localStorage.getItem('localItemsFavourite'));
  }, []); */

  


  function addFavoritesHandler(favoriteMeetup) {
    setUseFavorites((prevUseFavorites) => {
        return prevUseFavorites.concat(favoriteMeetup);
    });
  }

  function removeFavoritesHandler(meetupId) {
    setUseFavorites((prevUseFavorites) => {
        return prevUseFavorites.filter(meetup => meetup.id !== meetupId);
    });
  }

  function itemIsFavoritesHandler(meetupId) {
    return useFavorites.some(meetup => meetup.id === meetupId);
  }

  const context = {
    favorites: useFavorites,
    totalFavorites: useFavorites.length,
    addFavorite: addFavoritesHandler,
    removeFavorite: removeFavoritesHandler,
    itemIsFavorite: itemIsFavoritesHandler,
  };

  return (
    <FavoritesContext.Provider value={context}>
      {props.children}
    </FavoritesContext.Provider>
  );
}

export default FavoritesContext;

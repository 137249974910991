import Flickity from "react-flickity-component";
import "../../flickity.css";

const flickityOptions = {
  initialIndex: 1,
  autoPlay: 1500,
};

function Carousel() {
  return (
    <>
    <h1>Carousel Example</h1>
      <Flickity
        className={"carousel"} // default ''
        elementType={"div"} // default 'div'
        options={flickityOptions} // takes flickity options {}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        static // default fals
      >
        <img
          src="https://legacy.reactjs.org/logo-og.png"
          alt="React Carousel Image1"
        />
        <img
          src="https://legacy.reactjs.org/logo-og.png"
          alt="React Carousel Image1"
        />
        <img
          src="https://legacy.reactjs.org/logo-og.png"
          alt="React Carousel Image1"
        />
        <img
          src="https://legacy.reactjs.org/logo-og.png"
          alt="React Carousel Image1"
        />
        <img
          src="https://legacy.reactjs.org/logo-og.png"
          alt="React Carousel Image1"
        />
      </Flickity>
    </>
  );
}

export default Carousel;

import classSocial from "./SocialMedia.module.css";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const SocialMediaWidget = (props) => {
  //console.log(socialMedia);

  return (
    <>
      <hr></hr>
      <div className={classSocial.widget_align}>
        <ul
          id="share-more"
          className={`${classSocial.icon_liststyle}`}
          aria-hidden="true"
        >
          <li className={classSocial.icon_listlist}>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${props.post_link}`}
              className=""
              target="_blank"
              rel="noreferrer"
              data-type="share"
              data-share-service="facebook"
            >
              <span className="" aria-hidden="true"></span>
              <FaFacebook className={classSocial.icon_sizes} />
            </a>
          </li>
          <li className={classSocial.icon_listlist}>
            <a
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${props.post_link}`}
              className=""
              target="_blank"
              rel="noreferrer"
              data-type="share"
              data-share-service="linkedin"
            >
              <span className="" aria-hidden="true"></span>
              <FaLinkedin className={classSocial.icon_sizes} />
            </a>
          </li>
          <li className={classSocial.icon_listlist}>
            <a
              href={`https://twitter.com/intent/tweet?url=${props.post_link}`}
              className=""
              target="_blank"
              rel="noreferrer"
              data-type="share"
              data-share-service="twitter"
            >
              <span className="" aria-hidden="true"></span>
              <FaTwitter className={classSocial.icon_sizes} />
            </a>
          </li>
          <li className={classSocial.icon_listlist}>
            <a
              href={`https://mail.google.com/mail/u/0/?view=cm&su=SubjectTitle&body=${props.post_link}`}
              className=""
              target="_blank"
              rel="noreferrer"
              data-type="share"
              data-share-service="email"
            >
              <span className="" aria-hidden="true"></span>
              <MdEmail className={classSocial.icon_sizes} />
            </a>
          </li>
          {/*  <li className={classSocial.icon_listlist}>
          <a
            href=""
            onclick="window.print()"
            className=""
            data-type="share"
            data-share-service="print"
          >
            <span className="" aria-hidden="true"></span>Print
          </a>
        </li> */}
        </ul>
      </div>
    </>
  );
};

export default SocialMediaWidget;

import { Route, Routes } from "react-router-dom";

import HomePage from "./pages/Home";
import AllMeetupsPage from "./pages/AllMeetups";
import NewMeetupPage from "./pages/NewMeetup";
import FavoritesPage from "./pages/Favorites";
import Layout from "./components/layout/Layout";
import NewComponentsPage from "./pages/NewComponents";
import AllComponentLoadPage from "./pages/AllComponentLoadPage";
import PageNotFound from "./pages/PageNotFound";
import AllPostSingle from "./pages/AllPostSingle";

function App() {
  return (
    <>
      <Layout>
        <div className="body-content">
          <Routes>
            <Route index element={<HomePage />}></Route>
            <Route
              path="/all-components"
              element={<AllComponentLoadPage />}
            ></Route>
            <Route
              path="new-components"
              element={<NewComponentsPage />}
            ></Route>
            <Route path="/all-meetup" element={<AllMeetupsPage />}></Route>
            <Route path="/new-meetup" element={<NewMeetupPage />}></Route>
            <Route path="/favorites" element={<FavoritesPage />}></Route>
            <Route path="/all-post-single" element={<AllPostSingle />}></Route>
            <Route path="*" element={<PageNotFound />}></Route>
          </Routes>
        </div>
      </Layout>
    </>
  );
}

export default App;

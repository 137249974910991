import { Link } from "react-router-dom";
import classes from "./components.module.css";

function ComponentCard(props) {
  return (
    <>
      <section className={classes.wrapper}>
        <div>
          <h1>{props.title}</h1>
          <p>{props.description}</p>
          <div className={classes.component_button_wrap}>
            <Link to={props.url} state={{component_type: props.type}} className={'btn ' + classes.component_button}>
              Demo
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default ComponentCard;

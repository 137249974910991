import React from "react";
import classFilterOptions from "./FilterView.module.css";

const FilterOptions = ({ filter_show }) => {
  return (
    <div
      className={`${classFilterOptions.wp_filter_options} ${
        filter_show
          ? classFilterOptions.wp_filter_show
          : classFilterOptions.wp_filter_hide
      }`}
    >
      Filter Options
    </div>
  );
};

export default FilterOptions;

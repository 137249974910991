import React from "react";
import { FaBehance, FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
export const links = [
  {
    id: 1,
    url: "/",
    text: "Home",
    submenu: true,
    span: false,
  },
  {
    id: 2,
    url: "/new-components",
    text: "New Components",
    submenu: false,
    span: false,
  },
  {
    id: 3,
    url: "/favorites",
    text: "My Favorites",
    submenu: false,
    span: true,
  },
  {
    id: 4,
    url: "/404",
    text: "Page 404",
    submenu: false,
    span: false,
  },
];

export const social = [
  {
    id: 1,
    url: "https://www.twitter.com",
    icon: <FaFacebook />,
  },
  {
    id: 2,
    url: "https://www.twitter.com",
    icon: <FaTwitter />,
  },
  {
    id: 3,
    url: "https://www.twitter.com",
    icon: <FaLinkedin />,
  },
  {
    id: 4,
    url: "https://www.twitter.com",
    icon: <FaBehance />,
  },
];

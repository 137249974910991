import { Link } from "react-router-dom";
import classPost from "./AllPost.module.css";

function AllPost(props) {
  const { title, excerpt } = props.post;
  //const navig = useNavigate();
  //console.log(props.post.acf);
  //console.log(props.post.content.rendered);
  //console.log(props.post.link);

  return (
    <article className={classPost.post_wrapper}>
      <div className={classPost.post_wrap}>
        <div>
          {props.post_style && props.post._embedded["wp:featuredmedia"] && (
            <img
              className={classPost.image_wrapp}
              alt={props.post.id}
              src={
                props.post._embedded["wp:featuredmedia"][0].media_details.sizes
                  .full.source_url
              }
              loading="lazy"
            />
          )}
          <h3 className={classPost.heading}>{title.rendered}</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: excerpt.rendered,
            }}
          ></p>
        </div>
        <Link
          to="/all-post-single"
          state={{
            single_post_id: props.post.id,
            single_post_acf: props.post.acf,
            single_post: props.post.content.rendered,
            single_featured_image:
              props.post._embedded["wp:featuredmedia"] &&
              props.post._embedded["wp:featuredmedia"][0].media_details.sizes
                .full.source_url,
            single_post_link: props.post.link,
          }}
          className={classPost.read_more}
        >
          Read More
        </Link>
      </div>
    </article>
  );
}

export default AllPost;

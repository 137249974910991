import { useContext } from "react";
import MeetupList from "../components/meetups/MeetupList";

import FavoritesContext from "../store/favorites-context";

function FavoritesPage() {
  const favritesCtx = useContext(FavoritesContext);

  let content;
  if (favritesCtx.totalFavorites === 0) {
    content = <p>You have no favorites yet. Start adding now.</p>;
  } else {
    content = <MeetupList meetuparray={favritesCtx.favorites} />;
  }

  return (
    <section>
      <h1>Favorites</h1>
      {content}
    </section>
  );
}

export default FavoritesPage;

import { Navigate, useLocation } from "react-router-dom";
import ModalComponent from "../components/modal/ModalComponent";
import Carousel from "../components/carousel/Carousel";
import WpBase from "../components/wordpress/WpBase";

function AllComponentLoadPage() {
  const type_data = useLocation();
  const data = type_data.state;

  //console.log(type_data);

  if (data === undefined || data == null || data === "") {
    return <Navigate to="/" replace={true} />;
  } else {
    switch (data.component_type) {
      case "modal":
        return (
          <>
            <ModalComponent />
          </>
        );
      case "carousel":
        return (
          <>
            <Carousel />
          </>
        );
      case "worpress_rest_api":
        return (
          <>
            <WpBase />
          </>
        );
      default:
        return null;
    }
  }
}

export default AllComponentLoadPage;

import ComponentCard from "./ComponentCard";

function AllComponents(props) {
  return (
    <>
      {props.componentsarray.map((component) => (
        <ComponentCard
          key={component.id}
          id={component.id}
          title={component.title}
          description={component.description}
          url={component.url}
          type={component.type}
        />
      ))}
    </>
  );
}

export default AllComponents;

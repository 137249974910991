import React from "react";
import classLayout from "./Layout.module.css";
import { SiJavascript } from "react-icons/si";

const Footer = () => {
  return (
    <div className={classLayout.footer}>
      <p className={classLayout.footer_tag}>
        Learning React
        <span className={classLayout.footer_icon}>
          <SiJavascript />
        </span>
      </p>
    </div>
  );
};

export default Footer;

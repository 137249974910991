import { useLocation } from "react-router-dom";
import classSinglePost from "../components/wordpress/AllPost.module.css";
import SocialMediaWidget from "../components/SocialMediaWidget/SocialMediaWidget";

const AllPostSingle = () => {
  const single_content = useLocation();
  const data = single_content.state;
  const social_media_widget = data.single_post_acf.test_page_settings;

  return (
    <>
      {data.single_featured_image && (
        <img
          className={classSinglePost.single_post_featured_image}
          alt="Single page featured"
          src={data.single_featured_image}
          loading="lazy"
        />
      )}
      <h1>Single page</h1>
      <div dangerouslySetInnerHTML={{ __html: data.single_post }}></div>
      {social_media_widget && (
        <SocialMediaWidget post_link={data.single_post_link} />
      )}
    </>
  );
};

export default AllPostSingle;

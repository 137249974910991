import classPagination from "./Pagination.module.css";

function PaginationButton(props) {
  return (
    <>
      <div className={classPagination.pages}>
        {props.current_page > 1 && (
          <button onClick={props.previous}>
            ← <span className={classPagination.nav_text}>PREV</span>
          </button>
        )}
        <span className={classPagination.nav_text}>
          Page {props.current_page} of {props.total_pages}
        </span>
        {props.current_page < props.total_pages && (
          <button onClick={props.next}>
            <span className={classPagination.nav_text}>NEXT</span> →
          </button>
        )}
      </div>
    </>
  );
}

export default PaginationButton;
